:root{
	--color_red: #FF5C5C;
	--color_green: #89E689;
	--color_dark_green: #1b6b30;
	--color_yellow: #DAE073;
	--color_teal: #6FD5D5;
	--color_blue: #87ceff;
	--color_orange: #FEC46E;
	--color_nos_orange: #eb6809;
	--color_light_purple: #C579FF;
	--color_purple: #9494E0;
	--color_dark_purple: #800080;
	--color_grey: #DDDBDB;
	--color_white: #ffffff;
	--color_black: #000000;
	
	--color_table_even: #e5e5e5;
	--color_table_uneven: #ffffff;
	--color_border: #999999;
	
	--color_box_shadow: #00000030;
}