@import "variables";

.we_frontend {
	margin-bottom: 5rem;
	
	.clickable {
		cursor: pointer;
		user-select: none;
	}
	
	.button_green {
		color: var(--color_dark_green);
		font-size: 48px;
		border: 2px solid var(--color_dark_green);
		border-radius: 50%;
		
		> i {
			border: none;
		}
	}
	
	.page-headline {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
		
		> h3 {
			font-size: 4rem;
		}
	}
	
	.we-overview-articlelist {
		> tbody {
			> tr {
				> td:nth-child(1) {
					width: 17%;
				}
				
				> td:nth-child(2) {
					width: 12%;
				}
				
				> td:nth-child(3) {
					width: 8%;
				}
				
				> td:nth-child(4) {
					width: 8%;
				}
				
				> td:nth-child(5) {
					width: 7%;
				}
				
				> td:nth-child(6) {
					width: 6%;
				}
				
				> td:nth-child(7) {
					width: 3%;
				}
				
				> td:nth-child(8) {
					width: 13%;
				}
				
				> td:nth-child(9) {
					width: 10%;
				}
				
				> td:nth-child(10) {
					width: 11%;
				}
				
				> td:nth-child(11) {
					width: 5%;
				}
			}
		}
	}
}

.prod_tools{
	*{
		font-size: 2rem;
	}
	
	h3{
		font-size: 3rem;
	}
}

.bold {
	font-weight: bold;
}

.no-break {
	word-break: keep-all;
}

.flex{
	display: flex;
	
	&.row{
		flex-direction: row;
		&::before{
			content: none;
		}
		&::after{
			content: none;
		}
	}
	&.column{
		flex-direction: column;
	}
	
	&.space-between{
		justify-content: space-between;
	}
	
	&.center{
		justify-content: center;
	}
	
	&.align-center{
		align-items: center;
	}
	
	&.gap{
		gap: 1rem;
	}
	
	&.wrap{
		flex-wrap: wrap;
	}
}

.clickable{
	cursor: pointer;
}